import React from "react";
import { Accordion } from "@mantine/core";
import { Row, Col } from "antd";
import loadable from "@loadable/component";
import Section from "../../../components-v2/Base/Section";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  formItemTypes,
} from "../../../components/GatedAsset/constants";
import ogImage from "../../../images/global_assets/og-image.png";
import { webinarPointsOfProfit } from "../../../data/landing/gated-v2";
import { accordionWebinarPointsOfProfit } from "../../../data/landing/gated-accordion";
import { lineOfBusiness, zipValidationRegExp } from "../../../constants";

function Icon() {
  return (
    <svg
      className="plus-icon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="vertical-line"
        d="M15 1V29"
        stroke="#090D12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="horizontal-line"
        d="M1 15H29"
        stroke="#090D12"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const GatedForm = loadable(() =>
  import("../../../components/CustomGated/GatedForm")
);
const Image = loadable(() =>
  import("../../../components/ImageQuerys/LargeFeaturesImages")
);
const GatedSpecial = loadable(() =>
  import("../../../components/CustomGated/GatedSpecial")
);
const GatedSellingPoint = loadable(() =>
  import("../../../components/CustomGated/GatedSellingPoint")
);

const formDataToSend = ["firstname", "email", "phone", "company", "get_demo"];

const subtext = "";
const blockList = [
  "Content feature 1",
  "Content feature 2",
  "Content feature 3",
  "Content feature 4",
];

const data = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First Name",
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        min: 2,
      },
    ],
    placeholder: "Your First Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last Name",
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Please enter your last name",
        min: 2,
      },
    ],
    placeholder: "Your Last Name",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Business Name",
    name: "company",
    rules: [
      {
        required: true,
        message: "Please enter your business name",
        min: 3,
      },
    ],
    placeholder: "e.g. MyBusiness Inc. ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Business Phone",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.CHECKBOX,
    itemSubType: null,
    label: "Yes, I’d like a demo of SpotOn Restaurant point-of-sale.",
    name: "get_demo",
    visible: true,
  },
];

const GatedAssetv1 = () => {
  return (
    <LpLayout phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="SpotOn Gated Assets | Utilizing Technology to Increase Points of Profit in Your Restaurant Business."
        description="Download your free guide and see how you can get better insights about your business with cloud-based POS reporting. Cut costs, boost sales, and maximize your staff."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Row
        className="gate-heading-v1"
        gutter={[32, 32]}
        align="middle"
        id="restuarant-retail-planing"
      >
        <Col lg={12}>
          <div id="oaugd">
            <Heading
              injectionType={2}
              level={1}
              symbol="."
              className="text-white"
              withBlueSymbol
            >
              Utilizing Technology to Increase Points of Profit in Your
              Restaurant Business
            </Heading>
            <p className="gated-intro-para cta-light">
              Running a restaurant is hard work, so seeing how little of that
              work shows up in your bottom line can be discouraging.
              Implementing flexible technology is the most efficient way to
              improve your restaurant’s profit margins. Hard work can’t cut the
              fees from third-party delivery apps or get orders in and out of
              the kitchen faster, but technology can.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="gated-form-cont-v3">
            <GatedForm
              pageTitle="Title of the downloadable content"
              pageSubtext={subtext}
              btnText="Submit"
              formTitle="Download the free webinar"
              formId="4eb8938a-5deb-4106-80ab-7713c5c34a19"
              formItems={data}
              formDataToSend={formDataToSend}
              featureTitle="What you get"
              featureSubtext="Fill out the form to get your free download today."
              blockList={blockList}
              topImg="webinar-download.png"
              thankYouPath="/gated-assets/utilizing-technology-to-increase-points-of-profit/thank-you"
              gatedCampaign="manager-toolkit"
              lineOfBusiness={lineOfBusiness.HOSPITALITY}
            />
          </div>
          <div className="form-bg">
            <Image imageName="Form-Bg.png" />
          </div>
        </Col>
        <Col lg={24}>
          <div className="h-20 here-be-banner">
            <Image
              className="banner-text"
              imageName="free-webinar-banner.png"
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col lg={24} className="w-full">
          <GatedSpecial
            title="What you'll see"
            sectionData={webinarPointsOfProfit}
          />
        </Col>

        <Section>
          <Heading
            className="text-black text-center"
            level={2}
            injectionType={1}
            withBlueSymbol
          >
            {accordionWebinarPointsOfProfit.title}
          </Heading>

          <p className="text-black-200 text-[20px] text-center">
            {accordionWebinarPointsOfProfit.description}
          </p>

          <Accordion
            className="accordion-1 mt-16 2xl:mt-20"
            chevronPosition="right"
            disableChevronRotation
            // defaultValue="Michael Mina"
            multiple
          >
            {accordionWebinarPointsOfProfit.speakers.map((item) => (
              <Accordion.Item key={item.name} value={item.name}>
                <Accordion.Control chevron={Icon()} className="py-[30px]">
                  <div className="">
                    <Heading
                      level={3}
                      injectionType={3}
                      withBlueSymbol
                      className=""
                      noMargin
                    >
                      {item.name}
                    </Heading>
                    <div className="text-black-200 text-p -mt-4">
                      {item.position}
                    </div>
                  </div>
                </Accordion.Control>

                <Accordion.Panel className="text-black-200 text-p pb-4">
                  <div className="flex flex-col lg:flex-row gap-8 items-center md:items-start">
                    <Image
                      imageName={item.image}
                      className="md:max-w-[230px] min-w-[230px]"
                    />
                    <p className="text-p text-black-200 max-w-[770px]">
                      {item.text}
                    </p>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
          <p className="mt-10 pl-[16px] text-black-200 text-[16px]">
            Webinar developed in partnership with{" "}
            <a
              href="https://www.restaurateurconnection.com/"
              target="_blank"
              rel="noreferrer"
            >
              Restaurateur Connection
            </a>
            .
          </p>
        </Section>

        <Col lg={24}>
          <GatedSellingPoint
            mainPoint="Make your hard work more profitable with tools that work together"
            mark="."
            subtext="Running a restaurant is hard work, so seeing how little of that work shows up in your bottom line can be discouraging. See how you can improve your restaurant's profit margins by implementing flexible technology."
          />
        </Col>
      </Row>

      <Row
        gutter={[32, 32]}
        className="mt-10 lg:mt-20 mb-20 lg:mb-32 px-4 lg:px-0"
      >
        <Col xs={24}>
          <Image
            className="min-w-full"
            imageName="increase-points-of-profit-2.png"
          />
        </Col>
      </Row>
    </LpLayout>
  );
};

export default GatedAssetv1;
